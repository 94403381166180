.button {
    display: block;
    width: 200px;
    height: 45px;
    background: rgb(212, 115, 24);
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
}
.button:hover {
    color: white;
}