footer.disclosure {
    margin-top: 100px;
    color: #4a5666;
    font-size: 17px;
    letter-spacing: .35px;
    margin-bottom: 14px;
    background: #f5f6f7;
    border: 1px solid #b4bdc9;
    padding: 10px;
    /* border-radius: 11px; */
    text-align: center;
}

footer ul {
    text-align: center;
    list-style-position:inside;
    padding: 10px;
	margin: 10px;
}

footer li {
    display: inline;
    margin-left: 25px;
}
